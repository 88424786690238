import { ref } from 'vue';
import axios from 'axios';

export function useSpotifyApi() {
  const accessToken = ref(null);
  const isLoading = ref(false);
  const error = ref(null);

  const getAccessToken = async () => {
    const params = new URLSearchParams();
    params.append('grant_type', 'client_credentials');
    const client_id = '036b95d806e7499a98fe8082c0f3d21d';
    const client_secret = '6fac00763b134040b027b4f2eb6e2505';

    try {
      isLoading.value = true;
      const response = await axios.post('https://accounts.spotify.com/api/token', params, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': `Basic ${btoa(client_id + ':' + client_secret)}`
        }
      });
      accessToken.value = response.data.access_token;
    } catch (err) {
      error.value = err;
      console.error('Error retrieving access token', err);
    } finally {
      isLoading.value = false;
    }
  };

  const fetchArtist = async (artistId) => {
    if (!accessToken.value) await getAccessToken();

    try {
      isLoading.value = true;

      const response = await axios.get(`https://api.spotify.com/v1/artists/${artistId}`, {
        headers: {
          'Authorization': `Bearer ${accessToken.value}`
        }
      });
      
      return response.data;
    
    } catch (err) {
      error.value = err;
      
      console.error('Error fetching artist', err);
      
      return null;
    
    } finally {
      isLoading.value = false;
    }
  };

  const fetchSeveralArtists = async (artistIds) => {
    if (!accessToken.value) await getAccessToken();

    try {
      isLoading.value = true;

      const response = await axios.get(`https://api.spotify.com/v1/artists?ids=${artistIds.join('%2C')}`, {
        headers: {
          'Authorization': `Bearer ${accessToken.value}`
        }
      });
      
      return response.data.artists;
    
    } catch (err) {
      error.value = err;
      
      console.error('Error fetching artist', err);
      
      return null;
    
    } finally {
      isLoading.value = false;
    }
  };

  const fetchArtistTracks = async (artistId) => {
    if (!accessToken.value) await getAccessToken();

    try {
      isLoading.value = true;

      let totalAlbums = [];

      if(totalAlbums.length === 0) {
        await axios.get(`https://api.spotify.com/v1/artists/${artistId}/albums?include_groups=single%2Cappears_on%2Calbum&limit=50&offset=0`, {
          headers: {
            'Authorization': `Bearer ${accessToken.value}`
          }
        }).then(response => {
          totalAlbums = response.data.items;
        });
      }

      let repeat = false;
      let offset = 0;
      if(totalAlbums.length === 50) {
        repeat = true;
      }
      while (repeat) {
        await axios.get(`https://api.spotify.com/v1/artists/${artistId}/albums?include_groups=single%2Cappears_on%2Calbum&limit=50&offset=${offset}`, {
          headers: {
            'Authorization': `Bearer ${accessToken.value}`
          }
        }).then(response => {
          if(response.data.items.length < 50) {
            repeat = false;
          }
          totalAlbums = totalAlbums.concat(response.data.items);
          offset += 50;
        });
      }

      // Filtriamo gli album con più di una traccia e che non siano compilation
      const albumsWithManyTracks = totalAlbums
        .filter(album => album.total_tracks > 1)
        .filter(album => album.id && album.album_type !== 'compilation')
        .map(album => album.id);

      let tracks = totalAlbums;

      // Se ci sono album con più di una traccia, effettuiamo una chiamata per ottenere le tracce
      if (albumsWithManyTracks.length > 0) {
        for (let i = 0; i < albumsWithManyTracks.length; i += 20) {
          const tracksResponse = await axios.get(`https://api.spotify.com/v1/albums?ids=${albumsWithManyTracks.slice(i, i + 20).join('%2C')}`, {
            headers: {
              'Authorization': `Bearer ${accessToken.value}`
            }
          });

          // Aggiunta delle tracce ottenute alla lista esistente
          tracksResponse.data.albums.forEach(album => {
          // concatena solo le tracce che hanno negli artists l'id dell'artista
          tracks = tracks.concat(album.tracks.items.filter(track => track.artists.some(artist => artist.id === artistId)));
          });
        }

        // rimozione degli elmenti in albumsWithManyTracks da tracks e album_type == 'compilation'
        tracks = tracks.filter(track => !albumsWithManyTracks.includes(track.id) && track.album_type !== 'compilation' && track.album_type !== 'album');
      }

      // Rimozione delle tracce duplicate basate su id univoco di traccia
      const uniqueTracks = tracks.reduce((acc, current) => {
        const x = acc.find(item => item.name === current.name);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);

      // pusho i singoli in all songs mantenendo solo alcuni campi dell'oggetto
      let allSongs = [];
      uniqueTracks.forEach(single => {
        allSongs.push({
          album_group: single.album_group || 'called',
          album_type: single.album_type || 'single from album',
          id: single.id,
          name: single.name,
          release_date: single.release_date,
          url: single.external_urls.spotify,
        });
      });

      return allSongs;
    } catch (err) {
      error.value = err;
      console.error('Error fetching artist tracks', err);
      return [];
    } finally {
      isLoading.value = false;
    }
  };

  return { fetchArtist, fetchSeveralArtists, fetchArtistTracks, isLoading, error };
}