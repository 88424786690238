<!-- TODOS -->
<!--
  - terminare il gioco allo scadere del timer
  - Aggiungere un bottone per ricominciare il gioco
  - Aggiungere un messaggio di errore se non si riesce a caricare i dati
-->

<template>
  <div>
    <div v-if="isLoading">
      <LoadingComponent />
    </div>
    <div v-if="error">Failed to load data: {{ error.message }}</div>

    <div v-if="gamePhase === 0">
      <StartingPage @inputValue="(txt) => artistID = txt" @startGame="loadTracks()" />
    </div>

    <div v-if="gamePhase == 2" id="game">
      <div id="game-board">
        <div id="game-board-header">
          <div class="logo">
            <img src="@/assets/logo/logo-white.svg" alt="logo">
          </div>

          <div class="artist-image">
            <img :src="artist.images[0].url" alt="">
            <div class="gradient-overlay" />
          </div>

          <h2 class="artist-name">{{ artist.name }}</h2>
          <h3 class="score-count">Score: {{ score }}</h3>
        </div>

        <div id="game-board-body" ref="gameBoardBody">
          <ul>
            <li v-for="answer in answers" :key="answer.name">
              <span :class="{ 
                correct: answer.correct === true, 
                wrong: answer.correct === false, 
                repeated: answer.correct === 'alreadyAnswered'}"
              >
                {{ answer.name }}
              </span>
            </li>
          </ul>
        </div>

        <div id="game-board-footer">
          <h3 class="timer">
            Time left: 
            <span :class="{
              mid: timeLeft > 10 && timeLeft <= 30,
              low: timeLeft <= 10
            }">
              {{ timeLeft }}s
            </span>
          </h3>
          <input type="text" v-model="currentAnswer" @keyup.enter="pushAnswer">
          <button @click="pushAnswer">
            <i class="fa-regular fa-paper-plane"></i>
          </button>
        </div>
      </div>
    </div>

    <div v-if="gamePhase === 3">
      <div id="game-over">
        <EndPage 
          @restartGame="restartGame()" 
          :score="score"
          :answers="answers"
          :artist="artist.name"
        />
      </div>
    </div>

  </div>
</template>

<script>
import { useSpotifyApi } from '@/composables/useSpotifyApi';
import { useSpotifyLinkParser } from '@/composables/useSpotifyLinkParser';
import LoadingComponent from './components/LoadingComponent/LoadingComponent.vue';
import StartingPage from './components/StartingPage/StartingPage.vue';
import EndPage from './components/EndPage/EndPage.vue';

export default {
  name: 'App',
  components: {
    LoadingComponent,
    StartingPage,
    EndPage
  },
  data() {
    return {
      answers: [],
      currentAnswer: '',
      artistID: '',
      artist: null,
      // ---
      gamePhase: 0,
      // ---
      score: 0,
      timeLeft: 0, //seconds
      timer: null,
      // ----
      isLoading: false,
      tracks: [],
      error: null
    }
  },
  computed: {
    timerDuration() {
      const baseSeconds = 60;
      return Math.min(Math.max(baseSeconds, 60), 220);
    }
  },
  methods: {
    startTimer() {
      this.timeLeft = this.timerDuration;
      if (this.timer) {
        clearInterval(this.timer);
      }
      this.timer = setInterval(() => {
        if (this.timeLeft > 0) {
          this.timeLeft --;
        } else {
          this.stopTimer();
          this.gamePhase = 3;
          alert('Tempo Scaduto!');
        }
      }, 1000);
    },
    stopTimer() {
      clearInterval(this.timer);
      this.timer = null;
    },

    restartGame() {
      this.answers = [];
      this.currentAnswer = '';
      this.artistID = '';
      this.artist = null;
      this.gamePhase = 0;
      this.score = 0;
      this.timeLeft = 0;
      this.tracks = [];
      this.error = null;
    },

    async loadTracks() {
      const { fetchArtist, fetchArtistTracks, error } = useSpotifyApi();
      const { extractSpotifyArtistId } = useSpotifyLinkParser();
      this.artistID = extractSpotifyArtistId(this.artistID).artistId;
      this.stopTimer();
      this.gamePhase = 1;

      if (!this.artistID) {
        alert("Please enter an artist ID");
        this.gamePhase = 0;
        return;
      }

      if (error.value) {
        alert('Inserisci uno Spotify ID valido');
        this.gamePhase = 0;
        return;
      }

      this.isLoading = true;

      try {
        const artist = await fetchArtist(this.artistID);
        this.artist = artist;
        this.error = null;
      } catch (err) {
        this.error = err;
        this.gamePhase = 0;
        this.tracks = [];
      }

      try {
        const tracks = await fetchArtistTracks(this.artistID);
        this.tracks = tracks;
        this.error = null;

        let trackArrayForConsole = [];
        tracks.forEach(track => trackArrayForConsole.push(track.name));
        console.log('Tracks:', trackArrayForConsole);
      } catch (err) {
        this.error = err;
        this.tracks = [];
      } finally {
        this.isLoading = false;
      }

      this.gamePhase = 2;
      this.startTimer();
    },

    pushAnswer() {
      if (!this.currentAnswer) {
        alert("Please enter an answer");
        return;
      }

      const formattedAnswer = this.formatAnswer(this.currentAnswer);
      const matchingTrack = this.tracks.find(track => {
        const formattedTrackName = this.formatAnswer(track.name);
        return this.compareAnswers(formattedAnswer, formattedTrackName);
      });

      // INVALID ANSWER
      if (!matchingTrack) {
        this.answers.push(
          {
            name: this.currentAnswer,
            correct: false
          }
        );
        this.currentAnswer = '';
        return;
      }

      // ALREADY ANSWERED
      if (this.answers.some(answer => answer.name === matchingTrack.name)) {
        this.answers.push(
          {
            name: matchingTrack.name,
            correct: 'alreadyAnswered'
          }
        );
        this.currentAnswer = '';
        return;
      }

      // CORRECT ANSWER
      // cerca tutte le canzoni con lo stesso nome o simile e le inserisce come già risposte assegnando punti
      this.tracks.forEach(track => {
        const formattedTrackName = this.formatAnswer(track.name);
        if (this.compareAnswers(formattedAnswer, formattedTrackName)) {
          this.answers.push(
            {
              name: track.name,
              correct: true
            }
          );
          this.timeLeft++;
          this.score++;
          this.currentAnswer = '';
        }
      });
    },

    formatAnswer(answer) {
      return answer
      .toLowerCase()
      .replace(/[\s-.,]/g, '')
      .replace(/\[.*?\]/g, '')
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
    },

    compareAnswers(answer1, answer2) {
      let correctAnswer = answer2;
      // rimuove le parentesi e il contenuto
      correctAnswer = correctAnswer.replace(/\(.*\)/g, '');

      const answer1Length = answer1.length;
      const correctAnswerLength = correctAnswer.length;
      const maxLength = Math.max(answer1Length, correctAnswerLength);
      const tolerance = Math.floor(maxLength * 0.2);


      if (Math.abs(answer1Length - correctAnswerLength) > 1) {
        return false;
      }

      let errors = 0;
      let i = 0;
      let j = 0;

      while (i < answer1Length && j < correctAnswerLength) {
        if (answer1[i] !== correctAnswer[j]) {
          errors++;
          if (errors > tolerance) {
            return false;
          }

          if (answer1Length > correctAnswerLength) {
            i++;
          } else if (answer1Length < correctAnswerLength) {
            j++;
          } else {
            i++;
            j++;
          }
        } else {
          i++;
          j++;
        }
      }

      return true;
    }
  },
  updated() {
    this.$nextTick(() => {
      const element = this.$refs.gameBoardBody;
      if (element) {
        element.scrollTop = element.scrollHeight;
      }
    });
  }
}
</script>

<style lang="scss">
  @import './App.scss';
  @import './GameBoard.scss';
</style>
