<template>
  <div id="starting-page-container">
    <div class="header">
      <div class="logo">
        <img src="@/assets/logo/logo-white.svg" alt="logo">
      </div>
  
      <h1 class="start-title">
        Song Rush
      </h1>
      <h2 class="start-subtitle">
        Quante canzoni di un artista riesci a scrivere in 60 secondi?
      </h2>
    </div>

    <div class="link-form">

      <div class="input-box">
        <input 
          id="spotify-link-input"
          type="text" 
          v-model="inputValue" 
          placeholder=" " 
          @input="$emit('inputValue', inputValue)"
          @keyup.enter="$emit('startGame')"
        >
        <label for="spotify-link-input">
          <i class="fa-brands fa-spotify"></i>
          Spotify Link
        </label>
      </div>

      <button @click="$emit('startGame')" class="link-button">
        Gioca
      </button>

      <p>
        Inserisci il link Spotify dell’artista con cui vorresti giocare o scegline uno più sotto
      </p>
    </div>
  </div>

  <div id="suggested-artists">
    <div v-for="section in suggestedArtists" :key="section.category" class="suggestions-slider-container">
      <h2>{{ section.category }}</h2>
      <ul 
        class="suggestions-slider" 
        @mousedown="startDragging"
        @mouseleave="stopDragging"
        @mouseup="stopDragging"
        @mousemove="mouseMoveHandler"
      >
        <div v-for="artist in section.artists" :key="artist">
          <li v-if="artist" class="artist-card" @click="handleArtistClick(artist)">
            <div class="card-image">
              <img :src="artist.images[0].url" alt="artist image">
            </div>

            <h3 class="card-title">{{ artist.name }}</h3>
          </li>
        </div>
      </ul>
      <p class="scroll-instructions">
        <i class="fa-solid fa-arrow-left" />
        <span>drag here to scroll</span>
        <i class="fa-solid fa-arrow-right" />
      </p>
    </div>
  </div>
</template>

<script>
import Papa from 'papaparse';
import { useSpotifyApi } from '@/composables/useSpotifyApi';

export default {
  name: 'StartingPage',
  data() {
    return {
      inputValue: '',
      suggestedArtistsIds: [],
      suggestedArtists: [],
      isDragging: false,
      startX: 0,
      scrollLeft: 0,
    }
  },
  mounted() {
    this.$emit('inputValue', this.inputValue);

    Papa.parse('./artists-ids.csv', {
      download: true,
      header: true,
      complete: async (result) => {        
        // suddivide gli artisti in categorie
        const categories = [...new Set(result.data.map(artist => artist.category))];
        this.suggestedArtistsIds = categories.map(category => ({
          category,
          ids: result.data.filter(artist => artist.category === category).map(artist => artist.spotifyId)
        }));

        try {
          const { fetchSeveralArtists } = useSpotifyApi();
          for (let i = 0; i < this.suggestedArtistsIds.length; i++) {
            let tempArtits = await fetchSeveralArtists(this.suggestedArtistsIds[i].ids);

            this.suggestedArtists.push(
              {
                category: this.suggestedArtistsIds[i].category,
                artists: tempArtits
              }
            );
          }
          
          this.error = null;
        } catch (err) {
          this.error = err;
        }
        
        console.log('cat', this.suggestedArtists);
      }
    });
  },
  methods: {
    startDragging(e) {
      this.isDragging = true;
      this.startX = e.pageX - e.currentTarget.offsetLeft;
      this.scrollLeft = e.currentTarget.scrollLeft;
    },
    stopDragging() {
      this.isDragging = false;
    },
    mouseMoveHandler(e) {
      if (!this.isDragging) return;
      e.preventDefault();  // Previene la selezione del testo durante il trascinamento
      const x = e.pageX - e.currentTarget.offsetLeft;
      const walk = (x - this.startX) * 2; // velocità dello scroll
      e.currentTarget.scrollLeft = this.scrollLeft - walk;
    },
    handleArtistClick(artist) {
      if (!this.isDragging) {
        this.$emit('inputValue', artist.id);
        this.$emit('startGame');
      }
    }
  }
}
</script>

<style scoped lang="scss">
  @import './StartingPage.scss';
</style>
