<template>
  <div id="end-page-container">
    <h1>{{ artist }}</h1>
    <h2>Il tuo punteggio: {{ score }}</h2>

    <ul>
      <li v-for="answer in answers.filter(e => e.correct == true)" :key="answer.name">
        <span class="correct">{{ answer.name }}</span>
      </li>
      
    </ul>

    <button @click="$emit('restartGame')">Ricomincia</button>
  </div>
</template>

<script>

export default {
  name: 'StartingPage',
  props: {
    score: {
      type: Number,
      required: true
    },
    answers: {
      type: Array,
      required: true
    },
    artist: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
  @import './EndPage.scss';
</style>
