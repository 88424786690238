<template>
  <div class="loading-component-container">
    <div class="loading-item" />
    <h3>Loading...</h3>
  </div>
</template>

<script>
export default {
  name: 'LoadingComponent'
}
</script>

<style scoped lang="scss">
  @import './LoadingComponent.scss';
</style>
