export function useSpotifyLinkParser() {
  
  const extractSpotifyArtistId = (input) => {
    try {
      const regexUrl = /^https:\/\/open\.spotify\.com\/(?:intl-[a-z]{2}\/)?artist\/([a-zA-Z0-9]+)(?:\?.*)?$/
      const regexId = /^[a-zA-Z0-9]+$/
      
      if (regexId.test(input)) {
        // Input is already an artist ID
        return { success: true, artistId: input }
      }
      
      const match = input.match(regexUrl)
      if (match && match[1]) {
        return { success: true, artistId: match[1] }
      } else {
        throw new Error('Invalid Spotify artist link or ID.')
      }
    } catch (error) {
      return { success: false, error: error.message }
    }
  }

  return {
    extractSpotifyArtistId
  }
}
